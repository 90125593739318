



























import {Component, Emit, Prop, Provide, Vue} from 'vue-property-decorator'
import {Contract} from '@/model/resource/Contract'
import {InputContractNep17Schema} from '@/schema/resource/ContractNep17/InputContractNep17Schema'

@Component
export default class PersistContractNep17View extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputContractNep17Schema()
  contract = new Contract()

  async created() {
    await this.populate()
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.contract.getContract(id)
    }

    this.$await.done('getContract')
  }

  @Emit('persist')
  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.$toast.abort('system.error.validation')
    }
    return this.contract
  }
}
